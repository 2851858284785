'use client';

import Link from 'next/link';
import Image from 'next/image';
import { TailwindBreakPoints } from '@src/typescriptGlobals/types';
import { RootStateTypes, useTypedAppSelector } from '@src/redux/store/store.types';
import Container from '@src/components/Shared/Container';
import SectionHeader from '@src/components/Shared/SectionHeader';
import ParagraphSeparator from '@src/components/Shared/ParagraphSeparator';
import RenderBodyCopy from '@src/components/Shared/RenderBodyCopy';
import RenderButtons from '@src/components/Shared/RenderButtons';
import CarouselContainer from '@src/components/Shared/CarouselContainer';
import CarouselIndicators from '@src/components/Shared/CarouselIndicators';
import ArrowIOS from '@src/components/Icons/ArrowIOS';
import useCarouselAnimation from '@src/hooks/useCarouselAnimation';
import useCarouselIndicatorAnimation from '@src/hooks/useCarouselIndicatorAnimation';
import { ForceDecksBlogsCarouselPropTypes } from './ForceDecksBlogsCarousel.types';
const ForceDecksBlogsCarousel: React.FC<ForceDecksBlogsCarouselPropTypes> = ({
  attr,
  className,
  data
}) => {
  const {
    carouselRef,
    containerRef,
    handleAddCarouselItemsToRef,
    activeIndex,
    handleShowNextItem,
    handleShowPreviousItem,
    handleJumpToSlide,
    maxIndex,
    dragging
  } = useCarouselAnimation({});
  const cardsData = Array.isArray(data?.topicCollection?.items) ? data?.topicCollection?.items.map(item => {
    return {
      thumbnailUrl: item?.thumbnail?.url,
      thumbnailWidth: item?.thumbnail?.width,
      thumbnailHeight: item?.thumbnail?.height,
      thumbnailDes: item?.thumbnail?.description,
      slug: item?.slug
    };
  }) : [];
  const {
    handleAddIndicatorRef
  } = useCarouselIndicatorAnimation({});
  const {
    width
  } = useTypedAppSelector((state: RootStateTypes) => state.windowRXS);
  return <Container containerRef={containerRef} attr={attr} id={data?.scrollTo?.slice(1)} className={`overflow-x-hidden pb-[5rem] pt-[5rem] md:!overflow-x-visible lg:pb-[7rem] lg:pt-[7rem] 2xl:pt-[4rem] ${className}`} background='bg-[#f2f2f2]' data-sentry-element="Container" data-sentry-component="ForceDecksBlogsCarousel" data-sentry-source-file="ForceDecksBlogsCarousel.tsx">
      <div className='relative mb-[2.7rem] flex flex-col justify-between md:mb-[4.1rem] 2xl:mb-[3.2rem] 2xl:pt-[4rem]'>
        <SectionHeader className='lg:pb-[unset]' pretitle={data?.preTitle} title={data?.title} titleClassName='lg:max-w-[61rem] text-[2.8rem] lg:text-[4.8rem] mb-[2.4rem] leading-[3.2rem] md:leading-[4.6rem] lg:leading-[5rem] font-[300]' preTitleClassName='' data-sentry-element="SectionHeader" data-sentry-source-file="ForceDecksBlogsCarousel.tsx">
          <>
            <ParagraphSeparator data-sentry-element="ParagraphSeparator" data-sentry-source-file="ForceDecksBlogsCarousel.tsx">
              <RenderBodyCopy className='lg:max-w-[61rem]' bodyCopy={data?.bodyCopy?.json?.content} data-sentry-element="RenderBodyCopy" data-sentry-source-file="ForceDecksBlogsCarousel.tsx" />
            </ParagraphSeparator>
            <RenderButtons items={data?.buttonCollection?.items} data-sentry-element="RenderButtons" data-sentry-source-file="ForceDecksBlogsCarousel.tsx" />
          </>
        </SectionHeader>

        {width >= TailwindBreakPoints.lg && maxIndex > 0 && <div className='z-[10] mt-[3.4rem] flex'>
            <button aria-label='arrow' onClick={handleShowPreviousItem} disabled={activeIndex <= 0} className={`mr-[2rem] flex h-[5.5rem] w-[5.5rem] rotate-180 items-center justify-center rounded-full ${activeIndex <= 0 ? 'cursor-not-allowed' : ''}`}>
              <ArrowIOS className='' />
            </button>

            <button aria-label='arrow' onClick={handleShowNextItem} disabled={activeIndex >= maxIndex} className={`flex h-[5.5rem] w-[5.5rem] items-center justify-center rounded-full ${activeIndex >= maxIndex ? 'cursor-not-allowed' : ''}`}>
              <ArrowIOS className='' />
            </button>
          </div>}
      </div>
      <CarouselContainer carouselRef={carouselRef} className='[&>*:not(:last-child)]:mr-[1.6rem] md:[&>*:not(:last-child)]:mr-[2.4rem]' data-sentry-element="CarouselContainer" data-sentry-source-file="ForceDecksBlogsCarousel.tsx">
        {cardsData?.map((post, index) => {
        return <div ref={handleAddCarouselItemsToRef} key={post.slug} className={`w-[34.3rem] flex-none flex-shrink-0 md:w-[38rem] lg:w-[45rem]   ${index === activeIndex ? 'lg:origin-bottom lg:transition lg:delay-100 lg:duration-500' : ''}`}>
              <Link className={`rounded-2xl ${dragging ? 'pointer-events-none	' : ''}`} href={`/news/${post?.slug}`}>
                <span className='screen-reader-text'>Click here to read the article</span>
                <Image className='rounded-[1rem]' src={post?.thumbnailUrl} alt={post?.thumbnailDes} width={post?.thumbnailWidth} height={post?.thumbnailHeight} />
              </Link>
            </div>;
      })}
      </CarouselContainer>

      <CarouselIndicators className='mt-[3rem] flex justify-center' maxIndex={maxIndex} handleJumpToSlide={handleJumpToSlide} handleAddIndicatorRef={handleAddIndicatorRef} activeIndex={activeIndex} data-sentry-element="CarouselIndicators" data-sentry-source-file="ForceDecksBlogsCarousel.tsx" />
    </Container>;
};
export default ForceDecksBlogsCarousel;
'use client';

import React, { useEffect } from 'react';
import Container from '../Container';
import NextImage from '../NextImage';
import useGsapLoaded from '@src/hooks/useGsapLoaded';
import { CenteredCarouselPropTypes, ListItemType } from './CenteredCarousel.types';
const CenteredCarousel: React.FC<CenteredCarouselPropTypes> = ({
  list = [],
  className = ''
}) => {
  const isGsapLoaded = useGsapLoaded(); // Use the hook to check if GSAP is loaded

  useEffect(() => {
    if (isGsapLoaded) {
      // This code will now only run if GSAP is loaded
      window?.gsap?.set('.box', {
        x: (i: number) => i * 200
      });
      const animationOptions = {
        duration: 30,
        ease: 'none',
        x: '-=-2000',
        // Speed adjustment
        modifiers: {
          x: window?.gsap?.utils?.unitize((x: string) => parseFloat(x) % 2400) // Ensure proper casting
        },
        repeat: -1
      };

      // Stop any ongoing animations on '.box' elements
      window?.gsap?.killTweensOf('.box');
      window?.gsap.to('.box', animationOptions);
    }
  }, [isGsapLoaded]); // Depend on isGsapLoaded

  return <Container attr='centered-carousel-component' className={`!m-[0] mx-[-2.4rem] md:mx-[-6.3rem] xl:mx-[-12.8rem] 4xl:!px-[4rem] ${className}`} data-sentry-element="Container" data-sentry-component="CenteredCarousel" data-sentry-source-file="CenteredCarousel.tsx">
      <div className='absolute left-[2.4rem] z-[20] h-[100%] w-[10rem] bg-gradient-to-r from-background-tertiary from-[20%] md:left-[6.3rem] md:w-[20rem] xl:left-[12rem] 3xl:left-[3rem]'></div>
      <div className='absolute right-[2.4rem] z-[20] h-[100%] w-[10rem] bg-gradient-to-l from-background-tertiary from-[20%] md:right-[6.3rem] md:w-[20rem] xl:right-[12rem] 3xl:right-[3rem]'></div>
      <div className='relative mx-auto h-[250px] w-full overflow-hidden pt-[1rem]'>
        <div className='gap-5 relative left-[-16rem] flex w-full'>
          {[...list, ...list].map((item: ListItemType, index) => <React.Fragment key={`${item?.sys?.id}-${index}`}>
              <div className='box absolute w-[22rem] rounded-[0.6rem]'>
                <NextImage className='w-[22.4rem] rounded-[0.6rem] px-[2rem]' src={item?.media?.url} alt={item?.media?.description} aspectRatio={item?.media?.width / item?.media?.height} width={item?.media?.width} />
                <h2 className='mx-auto mt-[1.6rem] max-w-[80%] text-center text-[1.6rem] font-[500] leading-[1.8rem]'>
                  {item.title}
                </h2>
              </div>
            </React.Fragment>)}
        </div>
      </div>
    </Container>;
};
export default CenteredCarousel;